import { Injectable, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ItemsViewService {
    public isGridView = signal(true);

    toggleView(): void {
        this.isGridView.set(!this.isGridView());
    }
}
