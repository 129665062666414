import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { onFirstClick } from '../../../@common/DOM/close-on-first-click.function';

type ParameterName = 'hits' | 'language' | 'sort' | 'type' | 'year';

@Component({
    selector: 'app-dropdown',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ FaIconComponent, AsyncPipe ],
    styles: `
        @import "../../../../styles/variables";

        .dropdown {
            position: relative;
            margin: 0.2rem 0;
        }

        .button__parent {
            display: flex;
            align-items: center;
        }

        .dropdown-btn {
            outline: none;
            border: none;
            border-radius: 5px;
            background-color: $bg-light;
            color: $black;
            font-weight: 300;
            padding: 8px 14px;

            fa-icon {
                padding-left: 8px;
                font-size: 10px;
            }

            @media screen and (max-width: 500px) {
                padding: 6px 9px;
                font-size: 0.95rem;

                fa-icon {
                    padding-left: 1px;
                }
            }
        }

        .menu {
            margin-top: 0.5rem;
            padding: 8px;
            border-radius: 5px;
            background-color: $bg-light;
            visibility: hidden;
            position: absolute;
            z-index: 11;
            left: 0;
            bottom: 0;
            transform: translateY(calc(100% + 10px));
            max-height: 300px;
            min-width: 120px;
            overflow-y: auto;
            width: max-content;

            .dropdown-item {
                padding: 8px;
                border-radius: 5px;
                cursor: pointer;
                font-size: 14px;
                width: 100%;

                &:hover, &.active {
                    background-color: $grey-light;
                }
            }

            &.open {
                visibility: visible;
                transition: all 0.2s ease;
            }
        }

        .align-end {
            left: auto;
            right: 0;
        }
    `,
    template: `
        @let selectedName = selectedName$ | async ;

        <div class="dropdown">
            <div class="button__parent">
                <button class="dropdown-btn btn" (click)="toggleDropdown()">
                    {{ selectedName }}
                    <fa-icon [icon]="faChevronDown"/>
                </button>
            </div>
            <div class="menu" [class.open]="isOpened()" [class.align-end]="dropdownPosition === 'end'">
                @for (item of items; track item) {
                    <div class="dropdown-item" [class.active]="selectedName === item.name" (click)="select(item)">
                        {{ item.name }}
                    </div>
                }
            </div>
        </div>
    `
})
export class DropdownComponent {
    @Input({ required: true }) items: { name: string, value: string }[] = [];
    @Input({ required: true }) defaultName: string = '';
    @Input() parameter!: ParameterName;
    @Output() onClick = new EventEmitter<string>();
    @Input() dropdownPosition: 'start' | 'end' = 'start';
    selectedName$!: Observable<string>;
    isOpened = signal(false);
    faChevronDown = faChevronDown;

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.selectedName$ = this.route.queryParamMap.pipe(
            map(params => {
                const value = params.get(this.parameter);

                if (!value) {
                    return this.defaultName;
                }

                return this.items.find(i => i.value === value)?.name ?? this.defaultName;
            })
        );
    }

    toggleDropdown(): void {
        this.isOpened.set(!this.isOpened());

        if (this.isOpened()) {
            onFirstClick(() => this.isOpened.set(false));
        }
    }

    select({ name, value }: { name: string, value: string }): void {
        this.router.navigate([], { queryParams: { [this.parameter]: value }, queryParamsHandling: 'merge' })
        this.defaultName = name;
        this.onClick.emit(value);
    }

}
